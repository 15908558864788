import style from "./CardPropuestas.module.css";
import { NavLink } from "react-router-dom";

function Card({ id, imagen, titulo }) {
  return (
    <NavLink to={`/propuestas/${id}`} className={style.cardPropuesta}>
      <img src={imagen} alt="propuesta" />
      <div className={style.titleContainer}>
        <h4>{titulo}</h4>
      </div>
    </NavLink>
  );
}

export default Card;
